//
// Forms.scss
//


// checkbox input right

.form-check-right{
    padding-left: 0;
    display: inline-block;
    padding-right: 1.25rem;
    .form-check-input{
      right: 0;
      margin-left: 0;
    }
  
    .form-check-label{
      display: block;
    }
  }
  
  
  .custom-control-right{
    padding-left: 0;
    padding-right: 1.5rem;  
    display: inline-block;
    .custom-control-label{
      display: inline-block;
      &:before, &:after{
        left: auto;
        right: -1.5rem;
      }
    }
  
    .custom-control-input{
      left: auto;
    }
  }

  .status-1 {
    color: #2a567d;
    background-color: #dcedfc;
    border-color: #88c8ff;
    border-width: 2px; 
  }

  .status-2 {
    color: #7d5e28;
    background-color: #fcf0db;
    border-color: #ffce74;
    border-width: 2px;
  }

  .status-3 {
    color: #1b654a;
    background-color: #d6f3e9;
    border-color: #69eec0;
    border-width: 2px;
  }

  .status-4 {
    color: #7f3737;
    background-color: #fde1e1;
    border-color: #f7a4a4;
    border-width: 2px;
  }

  .radio-status-1 {
    color: #50a5f1;
  }

  .radio-status-2 {
    color: #f1b44c;
  }

  .radio-status-3 {
    color: #34c38f;
  }

  .radio-status-4 {
    color: #f46a6a;
  }

  

  .form-control {
    &:disabled {
      background-color: #ededed;
      opacity: 70;
      cursor: not-allowed;
    }
  }

  .valid {
    border: solid #20bf6b70 2px;
    background-color: #26de8130;
  }

  .invalid {
    border: solid #eb3b5a60 2px;
    background-color: #fc5c6540;
  }

  .warning {
    border: solid #fa823190 2px;
    background-color: #fd964460;
  }

  .moduleRow {
    padding: 1rem 0;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}