// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.validation-card {
    min-height: 150px;
}

.descriptionModule {
  line-height: 2rem;
}

.helpMessage {
  padding: 1rem 1rem 0 1rem;
}